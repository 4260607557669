import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import LoadingSpinner from "./LoadingSpinner";

// Lazy loaded components
const NavbarComponent = lazy(() => import("./Navbar"));
const VideoComponent = lazy(() => import("./mainVideo"));
const GridContainer = lazy(() => import("./gridContainer"));
const GridContainer2 = lazy(() => import("./gridContainer2"));
const FooterComponent = lazy(() => import("./footer"));
const HorizontalBanner = lazy(() => import("./HorizontalBanner"));
const Reviews = lazy(() => import("./reviews"));
const Pptx = lazy(() => import("./pptx"));
const Carmart = lazy(() => import("./carmart"));

const root = ReactDOM.createRoot(document.getElementById("root"));

const generalH1 = {
  textAlign: "center",
  fontFamily: "League Spartan",
  color: "#003C43",
  fontWeight: "900",
  marginTop: "3em",
  marginBottom: "1em",
};
function App() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <NavbarComponent />
      <Carmart />
      <VideoComponent />
      <HorizontalBanner />
      <h1 style={generalH1} id="Services">
        Our Core Services
      </h1>
      <GridContainer />
      <GridContainer2 />
      <Pptx />
      <Reviews />
      <iframe
        title="medium"
        src="https://widgets.sociablekit.com/medium-publication-feed/iframe/25442553"
        loading="lazy"
        style={{
          frameborder: "0",
          border: "none",
          width: "100%",
          height: "100vh",
          marginTop: "10%",
        }}
      ></iframe>
      <FooterComponent />
    </Suspense>
  );
}

// Hydration for client-side interactivity
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
